import { PrismicProvider } from '@prismicio/react';
import { Link } from 'gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import React from 'react';

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
  </PrismicProvider>
);
